/*global Component, wrapPost*/
class PersonModalComponent extends Component {

    static name() {
        return "PersonModalComponent";
    }

    static componentName() {
        return "PersonModalComponent";
    }

    data() {
        return {
            response: null,
            alertError: null,
            personName: '',
            personLastName: '',
            personCountry:'',
            personCity:'',
            personJobPosition:'',
            personLastName2: '',
            personEnabled: false,
            personWebUser: '',
            personWebPassword: '',
            personWebAccessGroup:'',
            personEmail: '',
        };
    }

    mounted() {
        return function () {
            //this.clearForm();
            this.$store.dispatch('updateSelectLinkto', {table: "modalPerson_Country",  value: this.$store.getters.getOurSettingsCountry});
            this.personCountry = this.$store.getters.getOurSettingsCountry;
            //console.log('UMounted PER MODAL',this.personCountry,this.$store.getters.getOurSettingsCountry)
        };
    }

    getMethods() {
        return {
            clearForm: this.clearForm,
            updateSelectValues:this.updateSelectValues,
            registerNewPerson:this.registerNewPerson,
            closeModal: this.closeModal,
        };
    }

    clearForm() {
        this.personName = '';
        this.personLastName = '';
        this.personLastName2 = '';
        this.personEnabled = false;
        this.personWebUser = '';
        this.personWebPassword = '';
        this.personEmail = '';
        this.$store.dispatch('clearSelectsLinkto', 'modalPerson');
        this.alertError = null;
        this.updateSelectValues();
    }

    updateSelectValues(event){
        //console.log('Modal Person',event)
        this.personJobPosition = this.$store.getters.getSelectLinkToValue('modalPerson_JobPosition');
        this.personWebAccessGroup = this.$store.getters.getSelectLinkToValue('modalPerson_WebAccessGroup');
        this.personCountry = this.$store.getters.getSelectLinkToValue('modalPerson_Country');
        this.personCity = this.$store.getters.getSelectLinkToValue('modalPerson_City');
    }

    async registerNewPerson() {
        this.alertError = null;
        //console.log('New PER MODAL',this.personCountry,this.$store.getters.getOurSettingsCountry);
        if (!this.personName)
            this.alertError = 'Please fill the field Name';
        else
            if (!this.personLastName)
                this.alertError = 'Please fill the field Last Name';
            else
                if (!this.personCountry)
                    this.personCountry = this.$store.getters.getOurSettingsCountry;
                else
                    if (!this.personWebUser)
                        this.alertError = 'Please fill the field User Web';
                    else
                        if (!this.personWebPassword)
                            this.alertError = 'Please enter a password';
                        else
                            if (!this.personWebAccessGroup)
                                this.alertError = 'Please indicate a Web Access Group';

        if (!this.alertError) {
            let data = {
                personName:this.personName,
                personLastName:this.personLastName,
                personLastName2: this.personLastName2,
                personCountry:this.personCountry,
                personCity:this.personCity,
                personEnabled: this.personEnabled,
                personJobPosition: this.personJobPosition,
                personWebUser:this.personWebUser,
                personWebPassword:this.personWebPassword,
                personWebAccessGroup:this.personWebAccessGroup,
                personEmail: this.personEmail,
                createCustomer:false
            };

            this.response = await wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/newPerson`,data);
            if (this.response.data.success) {
                let Person = {
                    Code: this.response.data.personCode,
                    Name: this.personName,
                    LastName: this.personLastName,
                    LastName2: this.personLastName2,
                    Country:this.personCountry,
                    City:this.personCity,
                    WebUserFlag: this.personEnabled,
                    JobPosition: this.personJobPosition,
                    WebUser: this.personWebUser,
                    WebPassword: this.personWebPassword,
                    WebAccessGroup: this.personWebAccessGroup,
                    Email: this.personEmail
                };
                //console.log(Person,'Person to add al state');
                this.$store.dispatch('AddCustomerPerson', Person);
                this.clearForm();
                this.closeModal();
                this.$store.dispatch('showNotificacion',{title: 'Crear Persona', message: 'Nueva persona creada satisfactoriamente',type: 'success'});
            }else {
                let errors = [];
                if(this.response.data.errors)
                    for(let error of this.response.data.errors)
                        if(error.code == "INVALID VALUE")
                            errors.push("Value not valid for field Email");
                        else
                            if(error.code)
                                errors.push(error.code);
                            else
                                errors.push(error);
                this.alertError = errors;
            }
        }
    }

    closeModal() {
        $("#PersonModal").modal('toggle');
    }

    getTemplate() {
        return`<div key="PersonModal" class="modal fade item-detail-modal" id="PersonModal" tabindex="-1" role="dialog">
                  <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title">{{tr("Personal Details")}}</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                          <div class="modal-body">
                                <div class="col-sm-12">
                                  <div ref='errorRegisterFrom' class="alert alert-danger alert-dismissible fade show" role="alert" v-if="alertError">
                                       <strong>{{tr(alertError)}}!</strong>
                                       <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alertError = null">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                   </div>
                                </div>                        
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group"> 
                                            <input autocomplete="none" name="hidden" type="text" style="display:none;">   
                                            <label for="firstname" class="form-label">{{tr('Name')}}</label>
                                            <input id="asjdlakhdlkashd" v-model="personName" type="text" class="form-control" autocomplete="off">
                                            <label for="lastname" class="form-label">{{tr('Last Name')}}</label>
                                            <input id="personLastName" v-model="personLastName" type="text" class="form-control" autocomplete="off">
                                            <label for="personCountry" class="form-label">{{tr('Country')}}</label>
                                            <selectLinkToComponent recordKey="modalPerson" :table ="'Country'" :paste ="'Code'" :showField ="'Name'" :selected="personCountry" @update-value="updateSelectValues"> </selectLinkToComponent>
                                            <label for="personMail" class="form-label">{{tr('Mail')}}</label>
                                            <input id="personMail" v-model="personEmail" type="text" class="form-control"> 
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                           <form autocomplete="off" > 
                                               <input autocomplete="none" name="hidden" type="text" style="display:none;">
                                               <label class="form-label">{{tr('User Activation')}}</label>
                                               <div class="custom-control custom-checkbox mb-3">
                                                  <input id="EnableCheck" class="custom-control-input" type="checkbox" v-model="personEnabled">
                                                  <label for="EnableCheck" class="custom-control-label">{{tr('Enabled')}}</label>
                                               </div>  
                                               <label for="WebUser" class="form-label">{{tr('User Web')}}</label>
                                               <input id="ajsdlasldkaljksd" v-model="personWebUser" type="text" class="form-control" autocomplete="off">
                                               <label for="WebPassword" class="form-label">{{tr('Password')}}</label>
                                               <input id="aldapdlaslkdalskdd" v-model="personWebPassword" type="password" class="form-control" autocomplete="off" >
                                               <label for="WebPassword" class="form-label">{{tr('Web Access Group')}}</label>
                                               <selectLinkToComponent recordKey="modalPerson" :table="'WebAccessGroup'" :paste="'Code'" showField="Code" :filters="{'IFNULL(Closed,0)__eq':'0'}" :selected="personWebAccessGroup" @update-value-full="updateSelectValues"> </selectLinkToComponent>
                                            </form> 
                                       </div>
                                    </div>
                                </div>     
                          </div>
                          <div class="modal-footer">
                                <button class="btn secondary-btn" @click="registerNewPerson">{{tr('Save')}}</button>                              
                                <button class="btn secondary-btn" @click="clearForm">{{tr('Clear')}}</button>
                          </div>
                      </div>
                  </div>
              </div>`;
    }
}

PersonModalComponent.registerComponent();